<template>
  <div>
    <v-btn color="primary lighten-3" @click="dialog=true" class="ml-3" small>
      <v-icon class="pr-2">mdi-briefcase-plus</v-icon>Nueva Produccion Intelectual
    </v-btn>
    <v-dialog v-model="dialog" max-width="700px" transition="dialog-transition">
      <v-card>
        <v-toolbar color="primary">
          <span class="subtitle white--text">{{titulo}}</span>
        </v-toolbar>
        <v-card-text class="mt-5">
          <v-form ref="formProduccionIntelectual" v-model="valid" lazy-validation>
            <Form :modelo="produccionIntelectual" :fields="fieldsProduccionIntelectual" :edit="edit" :create="true" />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancelarRegistro">cancelar</v-btn>
          <v-btn color="primary" @click="registrarProduccionIntelectual" :disabled="!valid" :loading="loadingSave">GUARDAR</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Form from "@/common/util/crud/Form";
export default {
  props: {
    id: Number,
    urlAdd:{
      type: String,
      default: 'abogado-ciudadano/producciones-intelectuales'
    },
    globalAdd:{
      type: Boolean,
      default: true
    }
  },
  components: { Form },
  data() {
    return {
      titulo: "Registrar Nueva Producción Intelectual",
      valid: true,
      edit: true,
      dialog: false,
      produccionIntelectual: {
        titulo: null,
        gestion: null,
        descripcion: null,
        tipo: null,
      },
      fieldsProduccionIntelectual: [],
      loadingSave:false
    };
  },
  mounted() {
    this.fieldsProduccionIntelectual = [
      {
        label: "Tipo Produccion",
        model: "tipo",
        rules: "requiredSelect",
        classField: "required px-1 pt-3",
        type: "select",
        items: this.$store.state.session.parametros.tipo_produccion_intelectual,
        class: "lg6 md6 xs6"
      },
      {
        label: "Gestion",
        model: "gestion",
        type: "number",
        class: "lg6 md6 xs6",
        rules: "required count",
        classField: "required px-1",
        counter :4
      },
      {
        label: "Titulo",
        model: "titulo",
        type: "text",
        class: "lg12 md12 xs12",
        rules: "required",
        classField: "required px-1"
      },
      {
        label: "Descripcion",
        model: "descripcion",
        type: "text",
        class: "lg12 md12 xs12",
        rules: "required",
        classField: "required px-1"
      },
    ];
  },
  methods: {
    registrarProduccionIntelectual() {
      if (this.$refs.formProduccionIntelectual.validate()) {
        this.loadingSave=true;
        this.$http
          .post(`${this.$apiUrl}${this.urlAdd}`, {
            producciones: [this.produccionIntelectual]
          })
          .then(response => {
             if(this.globalAdd){
                this.$store.commit('abogado/setProduccionIntelectual',response.data.data);
                this.$emit('creado',response.data.data[0])
            }else{
                this.$emit('creado',response.data.data)
            }
            this.cancelarRegistro();
          }).catch(error=>{
            console.log(error)
            this.loading=false
          });
      }
    },
    cancelarRegistro() {
      this.loadingSave=false;
      this.$refs.formProduccionIntelectual.reset();
      this.dialog = false;
    }
  }
};
</script>

